export default defineNuxtRouteMiddleware(() => {
  const localePath = useLocalePath()

  const { $clientPosthog } = useNuxtApp()

  if (!$clientPosthog) { return }

  const isEnabled = $clientPosthog.isFeatureEnabled('publisher-flow')

  if (!isEnabled) {
    return navigateTo(localePath('dashboard'))
  }
})
